import React from "react";
import { Pie } from "react-chartjs-2";

const DonutChart = ({ data }) => {
  const chartData = {
    labels: ["Conservative", "Progressive", "Neutral"],
    datasets: [
      {
        data: data,
        backgroundColor: ["#002AA2", "#3B62D1", "#779AFF"],
        hoverBackgroundColor: ["#002AA2", "#3B62D1", "#779AFF"],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // 범례를 표시하지 않도록 설정
      },
    },
    cutout: "0%", // 도넛 차트의 가운데를 채우도록 설정
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default DonutChart;
