import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import HomePage from "./pages/HomePage";
import IndicatorPage from "./pages/IndicatorPage";
import ResultPage from "./pages/ResultPage";
import ResourceListPage from "./pages/ResourceListPage";
import ResourcePage from "./pages/ResourcePage";
import AboutPage from "./pages/AboutPage";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/indicator" element={<IndicatorPage />} />
        <Route path="/result" element={<ResultPage />} />
        <Route path="/resources" element={<ResourceListPage />} />
        <Route path="/resource/:resourceId" element={<ResourcePage />} />
        <Route path="/about-us" element={<AboutPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
