import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // 스타일링을 위한 CSS 파일

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-nav">
          <div className="logo">
            <img src="/images/trulens_logo_b-02.png" alt="Logo" />
          </div>
          <nav className="nav-menu">
            <Link to="/indicator" className="nav-item">
              Indicator
            </Link>
            <Link to="/resources" className="nav-item">
              Resources
            </Link>
            <Link to="/about-us" className="nav-item">
              About us
            </Link>
          </nav>
        </div>
        <p>Sunhye, Koo | E-mail: sun.hye.koo08@gmail.com</p>
        <br></br>
        <p>This service was created using the open API provided by ChatGPT.</p>
        <p>
          Copyright 2024. Sunhye, Koo All pictures cannot be copied without
          permission.
        </p>
      </div>
    </div>
  );
};

export default Footer;
