import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // 스타일링을 위한 CSS 파일

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        <Link to="/" className="logo-container">
          <div className="logo">
            <img src="/images/trulens_logo_b-01.png" alt="Logo" />
          </div>
        </Link>
        <nav className="nav-menu">
          <Link to="/indicator" className="nav-item">
            Indicator
          </Link>
          <Link to="/resources" className="nav-item">
            Resources
          </Link>
          <Link to="/about-us" className="nav-item">
            About us
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
