import React, { useEffect, useState } from "react";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about">
      <div className="container">
        <h1>About Us</h1>
        <p>
          Trulens is a tool made for those living in Cambodia, who are wishing
          to enjoy media literacy in a more sensible way. We do this by
          indicating whether an article is conservative, progressive, or neutral
          along with some short written analysis on its tone, content, and
          origin. Our indicator is connected to ChatGPT's open API, which allows
          our indication results to be detailed and well-supported with proper
          reasoning.
        </p>

        <h2>Founder</h2>
        <div className="about-profile">
          <img className="profile-img" src="/images/profile.jpg"></img>
          <div>
            <h3>Sunhye (Bella) Koo</h3>
            <img src="/images/school.png"></img>
            <p>Email: sun.hye.koo08@gmail.com</p>
            <p>
              After facing many challenges myself from disinformation in
              Cambodia, I wanted to provide a tool for everyone in this country
              to use to enjoy media literacy in a more sensible way.{" "}
            </p>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default AboutPage;
