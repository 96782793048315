import React, { useState, useEffect } from "react";
import "./DynamicWidthBar.css"; // CSS 파일을 임포트합니다.

const DynamicWidthBar = ({ fetchPercentage, color }) => {
  const [percentage, setPercentage] = useState(0);

  /* useEffect(() => {
    const fetchData = async () => {
      const percentageFromServer = await fetchPercentage();
      setPercentage(percentageFromServer);
    };

    fetchData();
  }, [fetchPercentage]);*/
  useEffect(() => {
    setPercentage(fetchPercentage);
  }, [percentage]);

  return (
    <div className="DynamicWidthBar">
      <div
        className="bar"
        style={{
          width: `${percentage}%`,
          backgroundColor: color,
        }}
      ></div>
    </div>
  );
};

export default DynamicWidthBar;
