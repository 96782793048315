import React, { useEffect, useState } from "react";
import "./HomePage.css";
const HomePage = () => {
  return (
    <div className="home">
      <div className="home-top-bg">
        <div className="home-top">
          <div className="home-hero">
            <h1>TruLens</h1>
            <p>Cambodia’s First</p>
            <p className="bold">News Media Bias Indicator</p>
            <a href="/indicator">Get started!</a>
          </div>

          <div className="home-gpt">
            <p>Made using the API of</p>
            <img src="/images/chatgpt.png"></img>
          </div>
          <img className="home-circle" src="/images/circle.png"></img>
        </div>
      </div>
      <div className="home-3">
        <div className="home-3-container">
          <div className="home-3-card">
            <img src="/images/c1.png"></img>
            <h2>Our Story</h2>
            <p>
              Made by a student living in Cambodia, who wanted to stop the
              spread of misinformation by helping people avoid bias in media.
            </p>
          </div>
          <div className="home-3-card">
            <img src="/images/c2.png"></img>
            <h2>Our Vision</h2>
            <p>
              To help the people of Cambodia enjoy media more sensibly by
              analyzing the reliability of local articles that are suspected to
              be biased.
            </p>
          </div>
          <div className="home-3-card">
            <img src="/images/c3.png"></img>
            <h2>Technology</h2>
            <p>
              Made using ChatGPT’s open API, Trulens provides detailed article
              analysis based on its tone and content.
            </p>
          </div>
        </div>
      </div>
      <div className="home-step">
        <div className="home-step-1">
          <div className="home-step-desc">
            <h3>Step 1</h3>
            <h2>
              Copy and paste<br></br> the article
            </h2>
            <p>
              We accept articles from Khmer Times, Phnom Penh Post, VOA
              Cambodia, and The Independent Cambodia!
            </p>
            <a href="/indicator">Go to the indicator →</a>
          </div>
          <img src="/images/s1.png"></img>
        </div>
        <div className="home-step-2">
          <img src="/images/s2.png"></img>
          <div className="home-step-desc">
            <h3>Step 2</h3>
            <h2>
              Take a look <br></br>at the results!
            </h2>
            <p>
              Our indicator will show whether the article is conservative,
              progressive, or neutral, according to the tone, content, and
              origin of the article.
            </p>
          </div>
        </div>
        <div className="home-step-1">
          <div className="home-step-desc">
            <h3>Step 3</h3>
            <h2>Explore more!</h2>
            <p>
              Explore our contents to learn more about Cambodian media and ways
              to enjoy media content in a more sensible way!
            </p>
            <a href="/resources">Go to the Articles →</a>
          </div>
          <img src="/images/s3.png"></img>
        </div>
        <div className="home-brand">
          <p>
            Trulens is a tool made for those living in Cambodia,<br></br>
            wishing to enjoy media literacy in a more sensible way.
          </p>
        </div>
        <div className="home-review">
          <div className="home-review-box">
            <p className="star">★★★★★</p>
            <p className="name">
              Channkanika Hiv <br></br>(16, Female)
            </p>
            <p className="review">
              “An amazing tool to help avoid biased media”
            </p>
          </div>
          <div className="home-review-box">
            <p className="star">★★★★★</p>
            <p className="name">
              Kim Chhay Huot <br></br>(22, Male)
            </p>
            <p className="review">
              “Helped me gain awareness about media content”
            </p>
          </div>
          <div className="home-review-box">
            <p className="star">★★★★★</p>
            <p className="name">
              Vichara Pen <br></br>(18, Female)
            </p>
            <p className="review">
              “An innovative tool for the locals to use for sensible media
              usage”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
