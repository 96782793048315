import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ResultPage.css";
import DonutChart from "../components/common/DonutChart";
import DynamicWidthBar from "../components/common/DynamicWidthBar";
import "../components/common/chartjsSetup";
import axios from "axios";

const ResultPage = () => {
  const location = useLocation();
  const [review, setReview] = useState("");
  const { result, c_percentage, p_percentage, n_percentage } =
    location.state || {};
  const data = [c_percentage, p_percentage, n_percentage];
  const getResult = () => {
    if (c_percentage > p_percentage && c_percentage > n_percentage) {
      return "Conservative";
    } else if (p_percentage > c_percentage && p_percentage > n_percentage) {
      return "Progressive";
    } else {
      return "Neutral";
    }
  };
  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        //"http://localhost:3001/review",
        "https://trulens.asia/review",
        {
          review,
        }
      );
      alert("의견 주셔서 감사합니다.");
      setReview("");
    } catch (error) {
      console.error("There was an error sending the email!", error);
      alert("의견 주셔서 감사합니다.");
      setReview("");
    }
  };
  return (
    <div className="result">
      <div className="result-header">
        <div className="container">
          <h1>Results</h1>
          <p>According to the tone and content...</p>
          <div className="chart-box">
            <div className="chart">
              <DonutChart data={data} />
            </div>
            <div className="chart-desc">
              <h1>{getResult()}</h1>
              <h2>Conservative ({data[0]}%)</h2>
              <DynamicWidthBar
                fetchPercentage={[data[0]]}
                color={["#002AA2"]}
              />
              <h2>Progressive ({data[1]}%)</h2>
              <DynamicWidthBar
                fetchPercentage={[data[1]]}
                color={["#3B62D1"]}
              />
              <h2>Neutral ({data[2]}%)</h2>
              <DynamicWidthBar
                fetchPercentage={[data[2]]}
                color={["#779AFF"]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="result-contents">
        <div className="container">
          <div className="result-gpt">{result}</div>
          <h3>According to the media company...</h3>
          <p>
            Articles written by Khmer Times and Phnom Penh Post often show signs
            of conservative political ideology. This is because these two local
            media companies are well-known for their articles supporting the
            members of the Cambodia People’s Party (CPP) while focusing on the
            nation’s achievements as a whole. The articles on these websites
            also lack critical stances as they often avoid sensitive topics,
            which shows that the articles from these websites are most likely
            conservative.
          </p>
          <br></br>
          <p>
            On the other hand, articles published by VOA and The Independent
            often remain neutral. This is because they are foreign independent
            media companies (USA and UK) free from the Cambodian government’s
            pressure. Furthermore, their articles are usually written from an
            outsider’s point of view, which allows room for more criticism and
            neutrality.
          </p>
          <p></p>
          <h3>About Cambodian media...</h3>
          <p>
            Most Cambodian medias, specifically news articles, are often
            controlled by the government to prevent the spread of sensitive and
            explicit information regarding the CPP and the members of the
            government. This is why most articles from Cambodia media, are often
            more likely to be conservative, and more patriotic.
          </p>
          <h3>We value your opinion</h3>
          <p>
            If you want to make suggestions regarding your results, please
            contact us!
          </p>
          <textarea
            placeholder="Input your opinion"
            value={review}
            onChange={(e) => setReview(e.target.value)}
          ></textarea>
          <button onClick={sendEmail}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
