import React, { useEffect, useState } from "react";
import "./ResourceListPage.css";
const ResourceListPage = () => {
  return (
    <div className="resource-list">
      <div className="resource-list-header">
        <h1>Resources</h1>
        <span>
          Explore our articles to expand your knowledge on media literacy both
          within, and outside of Cambodia
        </span>
      </div>
      <a href="/resource/1" className="resource-list-1">
        <div className="container">
          <img src="/images/article1.png"></img>
          <div>
            <h2>How to indicate biased articles</h2>
            <p>
              In today’s information-saturated world, indicating bias in
              articles is crucial for enjoying information sensibly. This is
              because bias can skew perspective and affect critical thinking
              skills while spreading disinformation to mislead the audience. To
              help avoid such articles, here is a comprehensive guide on how to
              identify biased content in articles!{" "}
              <span className="read">Read</span>
            </p>
          </div>
        </div>
      </a>
      {/*
      <a href="/resource/2" className="resource-list-2">
        <div className="container">
          <img src="/images/article2.png"></img>
          <div>
            <h2>What are the characteristics of Cambodian media?</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. <span className="read">Read</span>
            </p>
          </div>
        </div>
  </a>*/}
    </div>
  );
};

export default ResourceListPage;
