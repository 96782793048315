import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./IndicatorPage.css";
const IndicatorPage = () => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가
  const navigate = useNavigate();
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // 로딩 시작
    try {
      const response = await axios.post("https://trulens.asia/analyze", {
        news_url: url,
      });
      console.log(response.data.result);
      console.log(
        response.data.c_percentage,
        response.data.p_percentage,
        response.data.n_percentage
      );
      navigate("/result", {
        state: {
          result: response.data.result,
          c_percentage: response.data.c_percentage,
          p_percentage: response.data.p_percentage,
          n_percentage: response.data.n_percentage,
        },
      });
    } catch (error) {
      console.error("Error:", error);
      alert("올바른 URL을 입력해주세요.");
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };
  return (
    <div className={`indicator ${isLoading ? "loading" : ""}`}>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader">
            <TailSpin color="#002AA2" height={80} width={80} />
          </div>
        </div>
      )}
      <div className="indicator-top">
        <div className="container">
          <h1>Indicator</h1>
          <p>
            Use our indicator to determine the reliability of an article! Simply
            copy and paste your URL and click the button.
          </p>
          <h2>Article URL</h2>
          <input
            type="text"
            placeholder="Article URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          ></input>
          <button onClick={handleFormSubmit}>Indicate</button>
        </div>
      </div>
      <div className="indicator-middle">
        <div className="container">
          <h1>How does it work? </h1>
          <p>
            Our website sends the URL of the article input by the user into
            ChatGPT using its API. Then we ask ChatGPT to analyze the article in
            a certain format that allows us to determine whether the article is
            conservative, progressive or neutral through percentage. We also
            require it to write a short justification on its analysis to show
            what makes the website conservative, progressive, or neutral, which
            can be broadly identified through its tone and content.
            Additionally, we provide an outline of each media company (Khmer
            Times, Phnom Penh Post, VOA Cambodia, and The Independent Cambodia)
            to show how different media companies can have different political
            alignment. 
          </p>
        </div>
      </div>
      <div className="indicator-bottom">
        <div className="container">
          <h1>How do I use it?</h1>
          <p>
            First, copy and paste the URL of the article that you would like us
            to analyze for you. Note that our website only accepts articles from
            Khmer Times, Phnom Penh Post, VOA Cambodia, and The Independent
            Cambodia. Next, click on the “Indicate” button, placed right below
            the input field, to view your results. Your page will then relocate
            to the results page, where you will see a pie chart with the result
            of the analysis, along with some additional justification and
            explanation about your results. If you are unsatisfied with the
            provided results, you can contact us through the suggestion field at
            the very bottom of the page, and we will try our best to reach back
            to you. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndicatorPage;
