import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ResourcePage.css";
const ResourcePage = () => {
  const { resourceId } = useParams();
  return (
    <div className="resource">
      <div className="container">
        <a href="/resources">Resources</a>
        <h1>
          {resourceId === "1"
            ? "How to indicate biased articles"
            : "What are the characteristics of Cambodian media?"}
        </h1>
        <div className="resource-contents">
          {resourceId === "1" ? (
            <>
              <p>
                In today’s information-saturated world, indicating bias in
                articles is crucial for enjoying information sensibly. This is
                because bias can skew perspective and affect critical thinking
                skills while spreading disinformation to mislead the audience.
                To help avoid such articles, here is a comprehensive guide on
                how to identify biased content in articles!
              </p>
              <h2> 1. Examine the Source</h2>
              <p>
                The source of the information is one of the key factors that can
                indicate the level of bias in articles. For example, CNN and Vox
                are known to skew to the left, while Fox News is known to skew
                to the right on the political spectrum. This shows that the
                level of bias can be largely dependent on the source of the
                information.
              </p>
              <p>
                The first recommended way to determine the reliability of a
                publication is to check its history and reputation. Oftentimes,
                established and reputable sources tend to be more reliable,
                while lesser-known or partisan outlets may present skewed
                viewpoints. However, be careful when you’re using this method to
                determine reliability as some well-known publications may also
                show biased viewpoints through their subtle use of biased
                language and content (e.g., misleading titles).
              </p>
              <p>
                Another way to examine the source of the article is by
                investigating the funding and ownership of the publication. This
                is because ownership by individuals or organizations with clear
                political or commercial interests can often influence the
                content of their articles. Therefore, by indicating such
                elements of publication companies, you can avoid biased media
                content.
              </p>
              <p>
                Finally, if you find the previous methods difficult, you can
                also use the Media Bias Chart made by Ad Fontes Media. The chart
                allows viewers to visually see where each publication lies on
                the political spectrum on its X-axis, from Most Extreme to the
                left, Hyper-Partisan Left, Strong Left, Skews Left, Middle,
                Skews Right, Strong Right, Hyper-Partisan Right, and Most
                Extreme to the right. The chart also shows their value and
                reliability on the Y-axis, where the bottom is the least
                reliable and the top is the most reliable. Below is an example
                of how the chart looks (version 12.0, January 2024 edition).
              </p>
              <img src="/images/media-bias.png"></img>
              <h2>2. Analyze Language and Tone</h2>
              <p>
                Language and tone in articles significantly affect the
                perception of bias. Emotive language, for example, can sway
                readers by eliciting strong emotional responses or casting
                subjects in a positive or negative light without factual
                support. Similarly, the tone can undermine objectivity by
                presenting information in a manner that aligns with the author’s
                intention, which can subtly influence the reader’s perspectives
                and viewpoints toward a certain topic.
              </p>
              <p>
                To analyze the language, you have to first look for emotive
                language. This is because biased articles often employ
                emotionally charged words to cause strong reactions. For
                example, words like “disgraceful,” “heroic,” or “disaster” can
                indicate bias due to their negative connotation. To analyze the
                tone, look for emotionally charged language, sarcasm, or
                condescension, which can indicate a slant. Additionally, assess
                whether the tone consistently supports one viewpoint while
                undermining others to reveal potential bias in the presentation.
              </p>
              <h2>3. Check for Balanced Reporting</h2>
              <p>
                A good, balanced article should fairly represent multiple
                perspectives, either positive or negative. This is because such
                reporting allows its readers to gain a deeper understanding of
                the situation with access to a well-rounded view, which
                encourages critical thinking and more nuanced discussions.
              </p>
              <p>
                To indicate the balance, first look into its representation of
                views. If only one side of an argument is presented or one
                viewpoint is disproportionately emphasized, the article is most
                likely biased, as it could be a way for them to block the
                readers’ access to viewpoints that oppose the preference of the
                article.
              </p>
              <p>
                You can also look at the quotations to indicate the balance. To
                do this, ensure quotes from different sides are used accurately
                and contextually. Misquoting or taking statements out of context
                can mislead readers, which could reflect the purpose of the
                article to present a biased viewpoint.
              </p>
              <h2>4. Scrutinize the Evidence</h2>
              <p>
                Carefully looking through the evidence of the information stated
                in the article is also a great way to determine reliability.
                This is mainly because reliable articles cite reputable sources
                while providing verifiable data and presenting evidence in a
                clear, transparent manner. Therefore, by checking the sources,
                readers can assess whether the article is well-researched and
                balanced, thereby ensuring they receive factual and unbiased
                information.
              </p>
              <p>
                Most data-based or information-based articles have their
                citation at the end of the article page. So, checking these
                sources can allow you to assess the validity of the article
                easily. For example, an article with sources like peer-reviewed
                journals, official statistics, and information from recognized
                experts is highly preferable in terms of reliability and
                validity because such sources have a higher chance of involving
                less bias.
              </p>
              <p>
                Another way to detect bias through evidence is to be wary of
                selective data presentation, also known as data manipulation.
                This is because some authors may cherry-pick data that supports
                their argument while ignoring the ones that contradict it.
                Hence, comparing the data presented in the article with the
                original data to find the difference in the way they are
                presented is a highly effective way to catch such bias.
              </p>
              <h2>5. Identify Logical Fallacies</h2>
              <p>
                A logical fallacy, in simple words, is an error in reasoning
                that renders an argument invalid, leading to an illogical
                conclusion that doesn’t logically follow from the points made.
                Some articles often make irrelevant and negative conclusions on
                a certain matter due to their personal bias against a belief,
                public figure, or occurrence, causing logical fallacies.
                Therefore, identifying such flaws can help you notice the bias
                involved in the article.
              </p>
              <p>
                A type of logical fallacy, known as the Ad Hominem Attack, is
                when someone tries to make their point through irrelevant
                personal attacks. An example of this fallacy would be when an
                article publicly attacks individuals rather than addressing
                their arguments or policies. When you notice such patterns or
                behaviors in an article, you must suspect the potential bias
                that the article may involve and check the other factors
                mentioned in this article to determine its level of validity.{" "}
              </p>
              <p>
                Another type of fallacy is called the Straw Man Argument, which
                involves misrepresenting an opponent’s argument to make it
                easier to attack. This can be shown in many articles when they
                oversimplify or distort opposing views to purposely cause
                misunderstandings among the readers. Therefore, identifying such
                fallacies is crucial to avoid getting negatively influenced to
                have a certain belief.
              </p>
              <h2> 6. Evaluate the Purpose </h2>
              <p>
                All articles have a purpose of being written such as informing,
                persuading, entertaining, explaining, analyzing, evaluating,
                instructing, narrating, and reflecting. When you are reading an
                article, it is important to recognize its purpose to see if it’s
                written to persuade the readers. This is because these sorts of
                articles use specific types of tones, language, and emotional
                appeals to manipulate the reader.
              </p>
              <p>
                The first step to identifying persuasive articles is to see if
                their opinion on an event or an individual is highly defined.
                This is because articles that are made to inform without bias,
                usually have a neutral stance on the topic that they are
                reporting about. On the other hand, persuasive articles show a
                strong indication of their bias towards a specific stance, to
                influence the readers. You can identify such articles by finding
                a hidden thesis statement, consistent argument, conclusion, and
                the author’s perspective.
              </p>
              <p>
                Next, you can look at the article to see if it has a Call to
                Action. A Call to Action is when an article urges its readers to
                take a specific action. This can include direct instructions
                such as "sign up now," "learn more," "donate today," "contact
                us," or "share this article." When you see anything similar to
                such instructions, you have to see if it aligns with its
                presented arguments so you can identify its intentions to avoid
                getting influenced unknowingly.
              </p>
              <p>
                In conclusion, identifying bias in articles is essential for
                navigating today's information-rich environment with
                discernment. By examining the source, analyzing language and
                tone, checking for balanced reporting, scrutinizing evidence,
                identifying logical fallacies, and evaluating the article's
                purpose, you can better discern the reliability and objectivity
                of the content they consume. This critical approach not only
                helps in avoiding misinformation but also promotes a more
                informed and nuanced understanding of complex issues, fostering
                healthier public discourse and more robust critical thinking
                skills.
              </p>
              <h2>Bibliography</h2>
              <p>
                Ad Fontes Media. Media Bias Chart. Ad Fontes Media, January
                2024, https://adfontesmedia.com/media-bias-chart-jan-2024/.
                Accessed 4 July 2024.
              </p>
              <p>
                Scribbr. Straw Man Fallacy. Scribbr,
                https://www.scribbr.com/fallacies/straw-man-fallacy/#:~:text=A%20straw%20man%20argument%20is,care%20about%20reading%20and%20writing.
                Accessed 11 July 2024.
              </p>
              <p>
                Study.com. Logical Fallacy: Definition & Examples. Study.com,
                https://study.com/academy/lesson/logical-fallacy-definition-examples.html#:~:text=What%20best%20defines%20a%20logical,logically%20follow%20from%20what%20preceded.
                Accessed 11 July 2024.
              </p>
            </>
          ) : (
            <>
              <strong>How to indicate biased articles</strong>
              <span> - This is an important topic.</span>
            </>
          )}
        </div>
        <a className="resource-btn" href="/resources">
          Go to Resources
        </a>
      </div>
    </div>
  );
};

export default ResourcePage;
